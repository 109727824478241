import Vue from 'vue'
import Router from 'vue-router'
import login from './views/pages/login'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/PhoneLlamar',
      beforeEnter: guard, // Using guard before entering the route      

      component: () => import('./views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'PhoneLlamar',
          path: '',
          beforeEnter: guard,
          component: () => import('@/views/dashboard/PhoneLlamar'),
        },
        {
          path: '/drawer',
          name: 'drawer',
          beforeEnter: guard,

          component: () => import('@/views/dashboard/drawer'),
        },
        // {
        //   path: '/historico',
        //   name: 'historico',
        //   beforeEnter: guard,

        //   component: () => import('@/views/dashboard/historico'),
        // },
      ],

    },
  ]

})




function guard(to, from, next) {
  var UserStorage = localStorage.getItem('UserStorage');


  if (UserStorage == null) {
   
      next('/')
    
  } else {
    next();
  }
}

