import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    instanciaPhone: "",
    registro: "",
    stateCrono: "reset",
    nombreLLamadaEntrante: "",
    llamadaEstado: "",
    verColgar: false,
    extensiontransferencia: "",
    iniciarBotonera: false,
    llamadaEntranteVentanaTime: false,
    estadoCerrar: false,

    Sipuser: '',
    Token: '',
    cerrarPhone: '',
    ExtensionHistorico: '',
    Llamadas: '',
    NumeroLlamar: '',
    RecargaListado: '',
    EstasRegistrado: '',
    tecladoDtmf: '',
    beep:false

  },
  mutations: {
    SET_INSTANCIAPHONE(state, instanciaPhone) {
      state.instanciaPhone = instanciaPhone;
    },
    SET_REGISTRO(state, registro) {
      state.registro = registro;
    },
    SET_STATECRONO(state, stateCrono) {
      state.stateCrono = stateCrono;
    },
    SET_NOMBRELLAMADAENTRANTE(state, nombreLLamadaEntrante) {
      state.nombreLLamadaEntrante = nombreLLamadaEntrante;
    },
    SET_LLAMADAESTADO(state, llamadaEstado) {
      state.llamadaEstado = llamadaEstado;
    },
    SET_VERCOLGAR(state, verColgar) {
      state.verColgar = verColgar;
    },
    SET_EXTENSIONTRANSFERENCIA(state, extensiontransferencia) {
      state.extensiontransferencia = extensiontransferencia;
    },
    SET_INICIARBOTONERA(state, iniciarBotonera) {
      state.iniciarBotonera = iniciarBotonera;
    },
    SET_LLAMADAENTRANTEVENTANATIME(state, llamadaEntranteVentanaTime) {
      state.llamadaEntranteVentanaTime = llamadaEntranteVentanaTime;
    },
    SET_ESTADOCERRAR(state, estadoCerrar) {
      state.estadoCerrar = estadoCerrar;
    },
    SET_SIPUSER(state, Sipuser) {
      state.Sipuser = Sipuser;
    },
    SET_CERRARPHONE(state, cerrarPhone) {
      state.cerrarPhone = cerrarPhone;
    },
    SET_TOKEN(state, Token) {
      state.Token = Token;
    },
    SET_EXTENSIONHISTORICO(state, ExtensionHistorico) {
      state.ExtensionHistorico = ExtensionHistorico;
    },
    SET_LLAMADAS(state, Llamadas) {
      state.Llamadas = Llamadas;
    },
    SET_NUMEROLLAMAR(state, NumeroLlamar) {
      state.NumeroLlamar = NumeroLlamar;
    },
    SET_RECARGALISTADO(state, RecargaListado) {
      state.RecargaListado = RecargaListado;
    },
    SET_ESTASREGISTRADO(state, EstasRegistrado) {
      state.EstasRegistrado = EstasRegistrado;
    },
    SET_TECLADODTMF(state, tecladoDtmf) {
      state.tecladoDtmf = tecladoDtmf;
    },
    SET_BEEP(state, beep) {
      state.beep = beep;
    }
  },
  getters: {
    getinstanciaPhone(state) {
      return state.instanciaPhone
    },
    getregistro(state) {
      return state.registro
    },
    getstateCrono(state) {
      return state.stateCrono
    },
    getnombreLLamadaEntrante(state) {
      return state.nombreLLamadaEntrante
    },
    getllamadaEstado(state) {
      return state.llamadaEstado
    },
    getverColgar(state) {
      return state.verColgar
    },
    getextensiontransferencia(state) {
      return state.extensiontransferencia
    },
    getiniciarBotonera(state) {
      return state.iniciarBotonera
    },
    getllamadaEntranteVentanaTime(state) {
      return state.llamadaEntranteVentanaTime
    },
    getestadoCerrar(state) {
      return state.estadoCerrar
    },
    getSipuser(state) {
      return state.Sipuser
    },
    getToken(state) {
      return state.Token
    },
    getcerrarPhone(state) {
      return state.cerrarPhone
    },
    getExtensionHistorico(state) {
      return state.ExtensionHistorico
    }
    ,
    getLlamadas(state) {
      return state.Llamadas
    }
    ,
    getNumeroLlamar(state) {
      return state.NumeroLlamar
    }
    ,
    getRecargaListado(state) {
      return state.RecargaListado
    },
    getEstasRegistrado(state) {
      return state.EstasRegistrado
    },
    gettecladoDtmf(state) {
      return state.tecladoDtmf
    }
    ,
    getbeep(state) {
      return state.beep
    }

  },
  actions: {
    setinstanciaPhone(context, instanciaPhone) {
      context.commit('SET_INSTANCIAPHONE', instanciaPhone)
    },
    setregistro(context, registro) {
      context.commit('SET_REGISTRO', registro)
    },
    setstateCrono(context, stateCrono) {
      context.commit('SET_STATECRONO', stateCrono)
    },
    setnombreLLamadaEntrante(context, nombreLLamadaEntrante) {
      context.commit('SET_NOMBRELLAMADAENTRANTE', nombreLLamadaEntrante)
    },
    setllamadaEstado(context, llamadaEstado) {
      context.commit('SET_LLAMADAESTADO', llamadaEstado)
    },
    setverColgar(context, verColgar) {
      context.commit('SET_VERCOLGAR', verColgar)
    },
    setextensiontransferencia(context, extensiontransferencia) {
      context.commit('SET_EXTENSIONTRANSFERENCIA', extensiontransferencia)
    },
    setiniciarBotonera(context, iniciarBotonera) {
      context.commit('SET_INICIARBOTONERA', iniciarBotonera)
    },
    setestadoCerrar(context, estadoCerrar) {
      context.commit('SET_ESTADOCERRAR', estadoCerrar)
    },

    setSipuser(context, Sipuser) {
      context.commit('SET_SIPUSER', Sipuser)
    },
    setToken(context, Token) {
      context.commit('SET_TOKEN', Token)
    },
    setcerrarPhone(context, cerrarPhone) {
      context.commit('SET_CERRARPHONE', cerrarPhone)
    },
    setExtensionHistorico(context, ExtensionHistorico) {
      context.commit('SET_EXTENSIONHISTORICO', ExtensionHistorico)
    },
    setLlamadas(context, Llamadas) {
      context.commit('SET_LLAMADAS', Llamadas)
    },
    setNumeroLlamar(context, NumeroLlamar) {
      context.commit('SET_NUMEROLLAMAR', NumeroLlamar)
    },
    setRecargaListado(context, RecargaListado) {
      context.commit('SET_RECARGALISTADO', RecargaListado)
    },
    setEstasRegistrado(context, EstasRegistrado) {
      context.commit('SET_ESTASREGISTRADO', EstasRegistrado)
    },
    setllamadaEntranteVentanaTime(context, llamadaEntranteVentanaTime) {
      context.commit('SET_LLAMADAENTRANTEVENTANATIME', llamadaEntranteVentanaTime)
    },
    settecladoDtmf(context, tecladoDtmf) {
      context.commit('SET_TECLADODTMF', tecladoDtmf)
    },
    setbeep(context, beep) {
      context.commit('SET_BEEP', beep)
    },
  },
})
