<template>
  <v-app>
    <v-spacer />
    <v-main>
      <v-card class="mx-auto my-12" width="450">
        <v-toolbar dark color="primary">
          <v-spacer />
          <v-toolbar-title>Acceso softphone</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-text-field label="Usuario" class="ml-4" v-model="correoUsuario" />
          <v-text-field
            label="Contraseña "
            type="password"
            class="ml-4"
            v-model="correoPass"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-show="canlogin"
            color="primary"
            class="mr-4"
            @click="checkLogin()"
          >
            Entrar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :right="true"
        :timeout="5000"
        :color="snack_color"
      >
        {{ snack_text }}
        <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>
    </v-main>
    <v-spacer />
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    token: "",
    snackbar: false,
    snack_color: "",
    snack_text: "",
    extension: "",
    extension_usuario: "",
    passExtension: "",
    mostrarFormulario: true,
    correoUsuario: "",
    correoPass: "",
    url: process.env.VUE_APP_API_URL,
  }),
  methods: {
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },

    checkLogin() {
      const goLogin = async () => {
        let config = {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        var usernameLocal = this.correoUsuario;
        var passwordLocal = this.correoPass;

        try {
          return await this.$axios.post(this.url + "auth/login", {
            emailUsuarioWeb: usernameLocal,
            passUsuarioWeb: passwordLocal,
            config,
          });
        } catch (error) {}
      };

      const constraints = {
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          console.log("Got MediaStream:", stream);
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });

      const processLoginResult = async () => {
        const login_result = await goLogin();
        if (login_result) {
          if (login_result.data.usuario.estadoUsuarioWeb == "true") {
            this.token = login_result.data.token;
            this.$store.dispatch("setToken", this.token);

            this.extension_usuario =
              login_result.data.usuario.extensionUsuarioWeb;
            const goExtension = async () => {
              let config = {
                headers: {
                  Accept: "application/json",
                  Authorization: this.token,
                },
              };
              try {
                return await this.$axios.get(this.url + "extension/", config);
              } catch (error) {}
            };

            const processExtensionResult = async () => {
              const extension_result = await goExtension();
              if (extension_result) {
                this.$store.dispatch("setSipuser", extension_result.data);
                this.$store.dispatch("setcerrarPhone", "");
                localStorage.setItem(
                  "UserStorage",
                  extension_result.data.sipuser[0].user +
                    "@" +
                    extension_result.data.sipuser[0].domain
                );
                this.$router.push("/PhoneLlamar").catch((err) => {});
              }
            };
            processExtensionResult();
          } else {
            this.go(
              "El usuario no tiene asginada ninguna extensión. Por favor, asígnesela antes de iniciar sesión.",
              "error"
            );
          }
        } else {
          this.go(
            "El usuario o la contraseña son incorrectos. Por favor, inténtelo de nuevo.",
            "error"
          );
        }
      };
      processLoginResult();
    },
  },
  computed: {
    canlogin: function () {
      return (
        this.correoUsuario !== "" &&
        this.correoPass !== "" &&
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
          this.correoUsuario
        ) == true
      );
    },
  },
};
</script>